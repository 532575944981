import * as React from 'react';
import { RouterProps } from 'react-router';

import logo from './assets/logo.svg';
import pyramid from './assets/pyramid.svg';
import david from './assets/team/david/david.png';
import fb from './assets/social/fb.svg';
import tw from './assets/social/tw.svg';
import ln from './assets/social/in.svg';
import cn from './HomePage.module.scss';

export interface HomePageProps extends RouterProps {}

export class HomePage extends React.PureComponent<HomePageProps> {
  public render() {
    return (
      <div className={cn.page}>
        <div className={cn.header}>
          <div className={cn.flames}>
            <img src={logo} className={cn.logo} alt='logo' />
            <div className={cn.title}>
              Venture Catalysts
            </div>
          </div>
        </div>
        <div>
          <div className={cn.strategy}>
            <div className={cn.content}>
              <div className={cn.topic}>
                Capital, Consulting & Client Connections
              </div>
              <div className={cn.benefits}>
                <span>Advance aviation &gt;</span>
                <span>Empower education &gt;</span>
                <span>Transform technology </span>
              </div>
            </div>
          </div>

          <div className={cn.goals}>
            <div className={cn.content}>
              <div className={cn.item}>
                <hr className={cn.line}/>
                <div className={cn.topic}>Why we care</div>
                <div className={cn.answer}>Pioneers discover new ways and places; this world needs more pioneers and a workforce prepared for automation.</div>
              </div>
              <div className={cn.item}>
                <hr className={cn.line}/>
                <div className={cn.topic}>How we work</div>
                <div className={cn.answer}>We craft meaningful stories to draw the right pioneers together for more innovation, trade &amp; prosperity worldwide.</div>
              </div>
            </div>
          </div>

          <div className={cn.products}>
            <div className={cn.content}>
              <div className={cn.offer}>
                <hr className={cn.line}/>
                <div className={cn.topic}>Who we serve</div>
                <div className={cn.answer}>Our best clients understand and uplift people toward peak “performance”, capturing the essence of triple bottom line (people, planet, prosperity). Sectors include Power, Water, Food, Shelter, Health, Communications, Finance, Security, Property Management, Lifestyle Services.</div>
              </div>
              <img src={pyramid} className={cn.pyramid}/>
            </div>
          </div>

          <div className={cn.team}>
            <div className={cn.content}>
              <hr className={cn.line}/>
              <div className={cn.topic}>Core Team</div>
              <div className={cn.members}>
                <div className={cn.member}>
                  <img src={david}/>
                  <div>
                    <div className={cn.name}>David J Roman</div>
                    <div className={cn.role}>Founder, System</div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={cn.feedback}>
            <div className={cn.content}>
              <hr className={cn.line}/>
              <div className={cn.topic}>Discover more</div>
              <form className={cn.form} method="POST" action="https://formspree.io/experience@spiritven.com">
                <div className={cn.field}>
                  <label>Email</label>
                  <input type='email' name='email' required />
                </div>
                <div className={cn.field}>
                  <label>Message</label>
                  <textarea name='message' required/>
                </div>
                <button type="submit" className={cn.btn}>
                  SEND
                </button>
              </form>
            </div>
          </div>

          <div className={cn.social}>
            <a href="http://facebook.com/spiritvencommunity">
              <img src={fb}/>
            </a>
            <a href="https://twitter.com/SpiritVen">
              <img src={tw}/>
            </a>
            <a href="https://www.linkedin.com/company/spirit-ventures-llc">
              <img src={ln}/>
            </a>
          </div>
        </div>
      </div>
    );
  }
}
