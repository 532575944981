import * as React from 'react';
import { BrowserRouter, Route } from 'react-router-dom';

import { HomePage } from './pages/homePage/HomePage';
import cn from './App.module.scss';

export type AppProps = {};

export class App extends React.PureComponent<AppProps> {
  public render() {
    return (
      <BrowserRouter>
        <div className={cn.root}>
          <div className={cn.content}>
            <Route exact={true} path="/" component={HomePage} />
          </div>
        </div>
      </BrowserRouter>
    );
  }
}
